
.CopyComponent { 
  position: relative;
  top: -0.25em;
}

.CopyComponent .fa-check {
  opacity: 1;
  display: none;
  position: relative;
  top: 0.25em;
  left: 0.5em;
  transition: opacity 1s;
  font-size: 0.75em;
}

.CopyComponent .fa-check.copied {
  display: block;
}

.CopyComponent .fa-check.fade {
  opacity: 0;
}


