:root {
  --light-grey: #e1e6eb;
  --primary: #0088a9;
  --secondary: #6c757d;
  --success: #35ad96;
  --info: #41b5dc;
  --warning: #ffa23f;
  --danger: #d24a35;
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-info {
  color: var(--info) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-danger {
  color: var(--danger) !important;
}
