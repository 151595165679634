

.ToastsComponent {
  padding: 1em;
  position: fixed;
  z-index: 100000;
  width: 475px;
  height: calc(100vh - 120px);
  top: 120px;
  right: 0;
  pointer-events: none;
}