
.ToastComponent {
  transition: height 350ms, margin 350ms, padding 350ms, transform 350ms;
  transform: translateX(0);
  pointer-events: all;
  padding-right: 15px;
}

.ToastComponent div {
  overflow: hidden;
  height: 100%;
}

.ToastComponent .more {
  padding-left: 1rem;
  border-left: 2px solid #bbb;
  margin-left: 0.75rem;
  margin-bottom: 1rem;
}

.ToastComponent.alert-dismissible button {
  right: 0;
}

.ToastComponent.dismissed {
  height: 0!important;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}