/*Avoid adding custom CSS, use whatever Cimpress and Bootstrap provide*/

.shipments-tile {
  width: 80px;
}

.shipments-tile-link {
  min-height: 75px;
}

.divider-v {
  border-left: 1px solid var(--light-grey);
  min-height: 100%;
}

.border-light-grey {
  border: 1px solid var(--light-grey);
  box-shadow: none;
}

.min-width-180 {
  min-width: 180px;
}

.max-width-375 {
  max-width: 375px;
}

.max-height-36 {
  max-height: 36px;
}

.min-height-0 {
  min-height: 0;
}

.page-title {
  margin-bottom: 3em;
  text-align: center;
}

iframe#for-print {
  border: none;
  height: 0px;
  width: 0px;
  position: absolute;
}

.card-edit {
  border-style: dashed;
  box-shadow: none;
}

.card-header-edit {
  border-bottom-style: dashed!important;
}

.clickable {
  cursor: pointer;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
}

.loading-overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 30000;
  background-color: #fff8;
}

.loading-overlay .spinner {
  position: fixed;
  top: calc(50vh - 36px);
}

.bring-to-top {
  z-index: 20000;
}

pre {
  white-space: pre-wrap; /* Wrap text inside of <pre> tags */
}

.divider-h {
  border-top: 1px solid var(--light-grey);
  min-width: 100%;
  margin-top: 1em;
  padding-top: 1em;
}

.summary {
  height: 72px;
}

.summary h2 {
  padding: 0 18px;
  border-right: 1px solid var(--light-grey);
}

.summary h2:last-child {
  border: 0;
}

.summary .icon-shift {
  position: relative;
  top: -0.25em;
  left: -0.5em;
}

.summary .icon-shift-2 {
  position: relative;
  top: 0.5em;
  left: 0.6em;
}

.shipments-tile i.fas {
  position: relative;
  top: 1.5em;
  left: -0.75em;
  font-size: 1.5em;
  line-height: 1em;
  height: 1em;
}

.pulse {
  animation: 0.85s ease-in 0s infinite alternate none running fade;
}

@keyframes fade { from { opacity: 1; } to { opacity: 0; }  }

mark.highlight {
  padding: 0;
  background-color: yellow;
  color: black;
}

.filter-textfield {
  margin: 0;
  width: 30%;
  max-width: 400px;
  min-width: 200px;
}

.manifests-header .rdt {
  width: 200px;
}

.manifests-header .form-group {
  margin-left: 20px;
}

div.d-flex button.btn.btn-default {
  border-color: #c4cdd6;
  border-width: 1px;
}

.w-20 {
  width: 20%;
}

button.btn input.form-control {
  border: 0;
  background-color: #ffffff00;
  color: #0088a9;
  font-weight: 600;
  text-align: center;
}

button.btn.btn-primary input.form-control {
  color: #ffffff;
}

.modal {
  height: 100vh;
  display: flex!important;
  justify-content: center;
}

.modal .modal-dialog {
  align-self: center;
  margin: 0;
}
